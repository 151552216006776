export async function postAnexo(
  token,
  alias,
  index,
  chave, // Alterado de key para chave
  base64,
  fileName // Alterado de file para fileName
) {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  let raw = JSON.stringify({
    alias: alias,
    index: 1,
    key: chave, // Alterado de key para chave
    base64: base64,
    file: fileName, // Alterado de file para fileName
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const apiUrl =
    "https://boanova183129.protheus.cloudtotvs.com.br:4050/rest/msdocument?empresa=01&filial=01";

  try {
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error post anexo:", error);
  }
}
