import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  Text,
  Center,
  VStack,
  Icon,
} from "@chakra-ui/react";

import { recuperarSenhaPost } from "../api/recuperar-senha-api";
import { useNavigate } from "react-router-dom";
import { IconAlertTriangle } from "@tabler/icons-react";

const RecoverPassword = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      // Envie a solicitação à API de recuperação de senha
      const response = await recuperarSenhaPost(email);

      // Verifique o código de status da resposta
      if (response.mensagem === "e-mail enviado!") {
        setMessage(
          "Um e-mail de recuperação de senha foi enviado para o endereço fornecido."
        );
        setErrorMessage(""); // Limpa a mensagem de erro se houver
      } else {
        // Se a resposta indicar um erro, exiba a mensagem de erro fornecida pela API
        setErrorMessage(
          "Erro ao processar a solicitação de recuperação de senha. Tente novamente mais tarde."
        );
        setMessage(""); // Limpa a mensagem de sucesso se houver
      }
    } catch (error) {
      // Se ocorrer um erro durante a solicitação, trate-o aqui
      console.error("Erro ao processar a solicitação:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      maxW="450px"
      mx="auto"
      mt="100px"
      p="8"
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="2xl"
      bg="white"
    >
      <Center mb="6">
        <Icon as={IconAlertTriangle} w={12} h={12} color="orange.400" />
      </Center>
      <Heading as="h2" size="lg" textAlign="center" mb="4" color="orange.600">
        Página em Manutenção
      </Heading>
      <Text mb="4" textAlign="center" fontSize="md" color="gray.700">
        A funcionalidade de recuperação de senha está temporariamente
        indisponível. Estamos trabalhando para resolver o problema o mais rápido
        possível.
      </Text>
      <Text mb="6" textAlign="center" fontSize="sm" color="gray.500">
        Caso tenha dificuldades com o acesso, entre em contato com o time{" "}
        <strong>Nexus</strong> para suporte.
      </Text>
      <VStack spacing="4">
        <Button
          colorScheme="orange"
          w="100%"
          onClick={() => navigate("/")}
          size="lg"
        >
          Voltar para a Página Inicial
        </Button>
      </VStack>
    </Box>
  );
};

export default RecoverPassword;
