export async function createItemPex(token, body) {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(body),
    redirect: "follow",
  };

  const apiUrl =
    "https://boanova183129.protheus.cloudtotvs.com.br:4050/rest/INT_SHELL_PEX_GRAVAITENS";

  try {
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error post items:", error);
  }
}
