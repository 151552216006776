import React, { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { getToken } from "../api/get-token-api";
import { getAnexos } from "../api/get-anexo-api";
import { Button, Progress, Text } from "@chakra-ui/react";
import { useAppContext } from "../context/AppContext";

function base64toBlob(base64String, contentType) {
  contentType = contentType || "";
  try {
    // Padroniza o comprimento da string
    while (base64String.length % 4) {
      base64String += "=";
    }

    const sliceSize = 1024;
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  } catch (error) {
    console.error("Erro ao converter base64 para Blob:", error);
    throw error;
  }
}

function DocViewerComponent({ chave, pagina, tamanhoPagina }) {
  const [token, setToken] = useState(null);
  const [dataState, setDataState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [isIndeterminate, setIsIndeterminate] = useState(true);
  const [arquivosCarregados, setArquivosCarregados] = useState(0);
  const [cancelado, setCancelado] = useState(false);
  const { emailGlobal, passwordGlobal } = useAppContext();
  const [error, setError] = useState(null);

  const getMimeType = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();

    const mimeTypes = {
      bmp: "image/bmp",
      csv: "text/csv",
      odt: "application/vnd.oasis.opendocument.text",
      doc: "application/msword",
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      gif: "image/gif",
      htm: "text/htm",
      html: "text/html",
      jpg: "image/jpg",
      jpeg: "image/jpeg",
      pdf: "application/pdf",
      png: "image/png",
      ppt: "application/vnd.ms-powerpoint",
      pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      tiff: "image/tiff",
      txt: "text/plain",
      xls: "application/vnd.ms-excel",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      mp4: "video/mp4",
    };

    return mimeTypes[extension] || "";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tokenData = await getToken(emailGlobal, passwordGlobal);
        setToken(tokenData.access_token);

        let currentPage = 1;
        let hasNextPage = true;
        const pageSize = 10;

        setIsIndeterminate(true);

        while (hasNextPage && !cancelado) {
          const chaveItems = await getAnexos(
            tokenData.access_token,
            "ZC4",
            chave,
            currentPage,
            pageSize
          );

          setDataState((prevData) => [...prevData, ...chaveItems.files]);
          setArquivosCarregados(
            (prevCount) => prevCount + chaveItems.files.length
          );

          hasNextPage = chaveItems.hasNext;

          currentPage++;
        }

        setIsIndeterminate(false);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar documentos:", error);
        setError(
          "Ocorreu um erro ao buscar os documentos. Por favor, tente novamente."
        );
        setLoading(false);
      }
    };

    fetchData();
  }, [chave, cancelado]);

  const handleCancel = () => {
    setCancelado(true);
  };

  const docs = dataState
    .filter((item) => item.fileBase64) // Filtra apenas arquivos com base64 válido
    .map((item) => {
      try {
        const blob = base64toBlob(item.fileBase64, getMimeType(item.file));
        return {
          uri: URL.createObjectURL(blob),
          fileName: item.file,
        };
      } catch (error) {
        console.error("Erro ao processar arquivo:", item.file, error);
        return null;
      }
    })
    .filter(Boolean); // Remove valores nulos

  const [rotation, setRotation] = useState(0);

  const handleRotate = () => {
    setRotation((prev) => prev + 90);
  };

  return (
    <div>
      {error && (
        <Text color="red.500" marginTop="4">
          {error}
        </Text>
      )}
      {dataState.some((item) => !item.fileBase64) && (
        <Text color="orange.500" marginTop="4">
          Alguns arquivos não puderam ser carregados devido a dados inválidos.
        </Text>
      )}
      <Button onClick={handleRotate}>Rotacionar</Button>

      {loading && !cancelado ? (
        <>
          <p>{`Carregando... (${arquivosCarregados} evidências carregadas)`}</p>
          <Progress
            size="sm"
            isIndeterminate={isIndeterminate}
            colorScheme="yellow"
          />
          <Button onClick={handleCancel} marginTop="4">
            Cancelar
          </Button>
        </>
      ) : (
        <>
          {cancelado && (
            <Text color="red.500" marginTop="4">
              Carregamento cancelado devido a tempo de espera muito longo.
            </Text>
          )}
          {docs.length === 0 && !error && (
            <Text color="gray.500" marginTop="4">
              Não há evidências disponíveis ou os arquivos estão inválidos.
            </Text>
          )}
          {docs.length > 0 && (
            <DocViewer
              style={{
                height: "900px",
                width: "1350px",
                transform: `rotate(${rotation}deg)`,
              }}
              config={{
                header: {
                  disableHeader: false,
                  disableFileName: false,
                  retainURLParams: false,
                },
              }}
              documents={docs}
              initialActiveDocument={docs[0]}
              pluginRenderers={DocViewerRenderers}
              language="pt"
            />
          )}
        </>
      )}
    </div>
  );
}

export default DocViewerComponent;
