export async function getActiveYear(token) {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const apiUrl =
    "https://boanova183129.protheus.cloudtotvs.com.br:4050/rest/avaliacoes_pex/periodo";

  try {
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error get pex items:", error);
  }
}
