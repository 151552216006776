export async function getAnexos(token, alias, chave, pagina, tamanhoPagina) {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const apiUrl = `https://boanova183129.protheus.cloudtotvs.com.br:4050/rest/msdocument?empresa=01&filial=01&alias=ZC4&key=${chave}&Page=${pagina}&PageSize=3`;

  try {
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error get anexo:", error);
  }
}
